import React, {useEffect} from 'react'
import "../sass/pages/_html_sitemap.scss";
import {Link} from 'gatsby'
import Seo from '../components/seo'
import { SchemaLinksToAbsolute } from '../helpers/SchemaLinksToAbsolute';
import { graphql } from 'gatsby'

export default function HtmlSitemap({data}) {

    useEffect(() => {
        if(typeof document !== 'undefined'){
          document.querySelector('body').setAttribute('data-bg-color', 'bg-white')
          document.querySelector('body').setAttribute('data-class', '')
          
        }
      }, [])

    return (
    <div className="case_studies html_sitemap">
        <Seo 
        title={'Edge45® HTML Sitemap'}
        description={'Having trouble finding what you are looking for? Our HTML sitemap will get you to where you need to be.'}
        date={data.wpPage.date}
        slug={'/'+data.wpPage.slug+'/'}
        robotsFollow={data.wpPage.seo.metaRobotsNofollow}
        robotsIndex={data.wpPage.seo.metaRobotsNoindex}
        canonicalUrl={data.wpPage.seo.canonical}
        />
        
        {data.wpPage.seo.schema.raw !== undefined ? 
            <SchemaLinksToAbsolute schemaRaw={data.wpPage.seo.schema.raw} /> 
        : "" }
        <section className={`intro is_inview`}>
          <div className="wrapper">
            <div className="left">
              <h1>HTML Sitemap</h1>
            </div>
          </div>
        </section>    
        <section className={`sitemap is_inview`}>
          <div className="wrapper">
            <section className="wrapper--inner">
                <h2>Pages</h2>
                <ul>
                {
                    data.pages.nodes.map(page => {
                        return (   
                        page.databaseId === 783 ? 
                            <li><Link to={`/insights/${page.slug}/`}>{page.title}</Link></li>
                            : <li><Link to={`/${page.slug === 'home' ? '' : page.slug+'/'}`}>{page.title}</Link></li>
                        )
                    })
                }
                </ul>
            </section>
            <section className="wrapper--inner">
                <h2>Services</h2>
                <ul>
                {
                    data.services.nodes.map(page => {
                       return (
                        <li><Link to={`${page.uri}`}>{page.title}</Link></li>
                       )
                    })
                }
                </ul>
            </section>
            {data.careers.nodes.length > 0 ? 
                <section className="wrapper--inner">
                    <h2>Career Opportunities</h2>
                    <ul>
                    {
                        data.careers.nodes.map(page => {
                        return (
                            <li><Link to={`/careers/${page.slug}/`}>{page.title}</Link></li>
                        )
                        })
                    }
                    </ul>
                </section>
                : '' 
            }
            <section className="wrapper--inner">
                <h2>Case Studies</h2>
                <ul>
                {
                    data.caseStudies.nodes.map(page => {
                       return (
                        <li><Link to={`${page.uri}`}>{page.title}</Link></li>
                       )
                    })
                }
                </ul>
            </section>
            <section className="wrapper--inner">
                <h2>Blog Posts</h2>
                <ul>
                {
                    data.blog.nodes.map(page => {
                       return (
                        <li><Link to={`${page.uri}`}>{page.title}</Link></li>
                       )
                    })
                }
                </ul>
            </section>
          </div>
        </section>
    </div>
    )
}


export const query = graphql`
{
    wpPage(title: {eq: "HTML Sitemap"}){
        seo{
            schema{
                raw
            }
            metaRobotsNofollow
            metaRobotsNoindex
            canonical
        }
        date
        slug
    }
    pages: allWpPage(sort: {order: ASC, fields: title}) {
      nodes {
        databaseId
        title
        slug
      }
    }
    services: allWpService(sort: {order: ASC, fields: title}) {
        nodes {
            title
            uri
        }
    }
    careers: allWpCareer(sort: {order: ASC, fields: title}) {
        nodes {
            title
            slug
        }
    }
    caseStudies: allWpCaseStudy(sort: {order: ASC, fields: title}) {
        nodes {
            title
            uri
        }
    }
    blog: allWpBlog(sort: {order: ASC, fields: title}) {
        nodes {
            title
            uri
        }
    }
  }
`